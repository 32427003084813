import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../layouts/Layout'
import Form from '../../components/Login/Login/Login'

const Login = () => {
  return (
    <Layout getInTouchVisible={false} blueFooter={true} login={true}>
      <Helmet>
        <title>Login - Emso</title>
      </Helmet>
      <Form/>
    </Layout>
  )
}

export default Login
