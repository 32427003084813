import * as React from 'react'
import { navigate } from 'gatsby'

import auther from '../../../helpers/auth'
import Hero from '../../Hero/Hero'
import heroImageMap from '../../MapSection/LargeMap.png'
import { hero } from './Form.module.scss'

const auth = auther()

export default props => {
  React.useEffect(() => {
    if (auth.isAuthenticated()) {
      navigate('/portal')
      return
    }
    auth.login()
  }, [])
  return (
    <Hero header={'Login'} image={heroImageMap} className={hero} content={[
      'Please wait..',
    ]}>
    </Hero>
  )
}
